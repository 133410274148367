import React from "react";
import banner from "../../assets/ourProductBannner.svg";
import "./styles.css";
const OurProducts = () => {
  return (
    <section className="w-100" id="products">
      <h2 className="text-center">Our Products</h2>
      <div
        className="mx-auto mb-4"
        style={{
          width: "8rem",
          height: "4px",
          backgroundColor: "#c2d72d", // Adjust the color to match the green line
        }}
      ></div>

      {/* <div className="row align-items-center">
        <div
          className="col-md-6 align-items-stretch"
          style={{ height: "100%" }}
        >
          <div
            className="p-4"
            style={{
              backgroundColor: "#003049",
              color: "white",
              height: "100%",
            }}
          >
            <h3 style={{ color: "#a2d100" }}>Digital Lending Solutions:</h3>
            <p>
              At First Fiscal Services India Limited, We Are Committed To
              Revolutionizing The Lending Landscape Through Our Cutting-Edge
              Digital Lending Solutions. With A Focus On Innovation And
              Customer-Centricity, Our Digital Lending Offerings Are Designed To
              Empower Businesses And Individuals With Seamless Access To Credit.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <img
            src={banner} // Replace with your image path
            alt="Digital Lending"
            className="img-fluid"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
     */}
      <div className="d-flex flex-wrap h-100 w-100 align-items-stretch">
        <div className="col-md-6 d-flex align-items-stretch p-0 m-0">
          <div
            className="p-4"
            style={{
              backgroundColor: "#003049",
              color: "white",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-lg-8 col-sm-11 col-md-11 col-11">
              <h3 style={{ color: "#a2d100" }}>Digital Lending Solutions:</h3>
              <p>
                At First Fiscal Services India Private Limited, We Are Committed
                To Revolutionizing The Lending Landscape Through Our
                Cutting-Edge Digital Lending Solutions. With A Focus On
                Innovation And Customer-Centricity, Our Digital Lending
                Offerings Are Designed To Empower Businesses And Individuals
                With Seamless Access To Credit.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-12 d-flex align-items-stretch p-0 m-0">
          <img
            src={banner} // Replace with your image path
            alt="Digital Lending"
            className="img-fluid product-banner"
          />
        </div>
      </div>
    </section>
  );
};

export default OurProducts;
