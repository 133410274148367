import React from "react";
import NavBar from "../components/navbar";
// import banner from "";
import AboutUs from "../components/abutUs";
import WhatDrivesUs from "../components/driveUs";
import OurProducts from "../components/ourProduct/ourProduct";
import FeaturesSection from "../components/digitalLanding";
import SourcingPartnersSection from "../components/sourcingPartners/sourcingPartners";
import IntroCrefin from "../components/introCrefin/introCrefin";
import ContactUs from "../components/contactUs/contactUs";
import ExploreOffering from "../components/Explore/exploreOffering";
import Footer from "../components/footer/Footer";
import EmiCalculator from "../components/emiCalculator/emiCalculator";
import { useEffect } from "react";
function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div style={{ position: "absolute", left: 0, top: 0, width: "100%" }}>
        <NavBar />
      </div>
      {/*
      <div className="w-100 homeBanner d-flex justify-content-end align-items-center">
        <div className="col-lg-6 col-sm-8 col-md-6 col-8 mx-2">
          <h3 className="text-center text-bold" style={{ fontSize: "40px" }}>
            Accelerating Financial Success with Next-Gen Lending Solutions
          </h3>
          <p
            className="text-center mt-4"
            style={{ fontWeight: "400", fontSize: "24px" }}
          >
            Partner with First Fiscal Services India Limited to revolutionize
            lending with our advanced digital platform, designed for speed,
            security, and seamless integration.
          </p>
        </div>
      </div>
*/}
      <div className="w-100 homeBanner p-4 d-flex justify-content-end align-items-center">
        <div
          className="col-lg-6 col-sm-10 col-md-6 col-11"
          style={{ marginTop: "6rem" }}
        >
          <h3
            className="text-center display-6 display-md-3 display-lg-6"
            style={{ color: "#023047", fontWeight: "500" }}
          >
            Accelerating Financial Success with Next-Gen Lending Solutions
          </h3>
          <p className="text-center mt-4 fs-4 fs-md-3 fs-lg-6">
            Partner with First Fiscal Services India Private Limited to
            revolutionize lending with our advanced digital platform, designed
            for speed, security, and seamless integration.
          </p>
        </div>
      </div>

      <AboutUs />
      <WhatDrivesUs />
      <OurProducts />
      <FeaturesSection />
      <EmiCalculator />
      <SourcingPartnersSection />
      <IntroCrefin />
      <ContactUs />
      <ExploreOffering />
      <Footer />
    </>
  );
}

export default Home;
