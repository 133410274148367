export default function AboutUs() {
  return (
    <section className="container py-5" id="about">
      <div className="text-center mb-4">
        <h2 className="font-weight-bold">About Us</h2>
        <div
          className="mx-auto"
          style={{
            width: "80px",
            height: "4px",
            backgroundColor: "#c2d72d", // Adjust the color to match the green line
            marginTop: "10px",
          }}
        ></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h5 className="font-weight-bold" style={{ color: "#182D64" }}>
            Your Trusted Financial Partner Since 1996
          </h5>
          <p>
            At First Fiscal Services India Private Limited, we pride ourselves
            on being a cornerstone of financial stability and growth for our
            clients. Established on January 30, 1996, we have consistently
            delivered tailored financial solutions that meet the diverse needs
            of individuals, businesses, and institutions.
          </p>
          <h6 className="font-weight-bold" style={{ color: "#182D64" }}>
            Corporate Identity
          </h6>
          <p>
            We are a private, non-governmental company registered under the
            Registrar of Companies, Delhi, with the{" "}
            <span style={{ color: "#182D64" }}>
              Corporate Identification Number (CIN): U65910DL1996PLC075852
            </span>{" "}
            Our long-standing presence in the financial sector underscores our
            commitment to reliability and trustworthiness.
          </p>

          <h6 className="font-weight-bold">Key Corporate Information</h6>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li>- Date of Incorporation: January 30, 1996</li>
            <li>- Company Type:Private , Non-Government</li>
            <li>- State of Registration: Delhi</li>
            <li>- Registrar of Companies: ROC-Delhi</li>
          </ul>
        </div>
      </div>
    </section>
  );
}
